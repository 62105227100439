import React, { useState, useEffect } from 'react';
import './Info.css';
import imginfo from './../../images/2.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBolt } from '@fortawesome/free-solid-svg-icons';
import { motion } from 'framer-motion';

function Info() {
    const [hasAnimated, setHasAnimated] = useState(false);

    const handleAnimation = () => {
        if (!hasAnimated) {
            setHasAnimated(true);
        }
    };

    return (
        <div className="info" id="about">
            <div className="section feature" aria-labelledby="feature-label">
                <div className="container-info">
                    <figure className="feature-banner">
                        <motion.img
                            src={imginfo}
                            width={800}
                            height={531}
                            loading="lazy"
                            alt="feature banner"
                            className="w-100"
                            initial={{ opacity: 1, x: -200 }}
                            animate={hasAnimated ? { opacity: 1, x: 0 } : { opacity: 0, x: -200 }}
                            transition={{ duration: 1.5 }}
                            onViewportEnter={handleAnimation}
                        />
                    </figure>
                    <div className="feature-content">
                        <motion.p
                            className="section-subtitle"
                            id="feature-label"
                            initial={{ opacity: 1, x: 200 }}
                            animate={hasAnimated ? { opacity: 1, x: 0 } : { opacity: 0, x: 200 }}
                            transition={{ duration: 1.5 }}
                            onViewportEnter={handleAnimation}
                        >
                            Why Choose AI SLIDE STUDIO for Your Presentations?
                        </motion.p>
                        <motion.h2
                            className="section-title-info"
                            initial={{ opacity: 1, x: 200 }}
                            animate={hasAnimated ? { opacity: 1, x: 0 } : { opacity: 0, x: 200 }}
                            transition={{ duration: 1.5 }}
                            onViewportEnter={handleAnimation}
                        >
                            "Create professional presentations in seconds with AI assistance!"
                        </motion.h2>
                        <motion.p
                            className="section-text"
                            initial={{ opacity: 1, x: 200 }}
                            animate={hasAnimated ? { opacity: 1, x: 0 } : { opacity: 0, x: 200 }}
                            transition={{ duration: 1.5 }}
                            onViewportEnter={handleAnimation}
                        >
                            AI Slide Studio makes creating, customizing, and sharing presentations easy and fast. Just input your topic, and it will generate ppt slides with suitable text and images in seconds, all in PowerPoint format so you can fully edit and customize them. It's a simple, efficient way to save time and ensure your presentations look great and make an impact.
                        </motion.p>
                        <ul className="feature-list">
                            {['Create high-quality slides 100x faster with AI.', 'Easily integrate with PowerPoint and Google Slides.', 'Save time and energy with AI-driven design and content creation.', 'Fully customize your slides with diverse font styles, colors, background options, and more to fit your unique needs'].map((text, index) => (
                                <motion.li
                                    key={index}
                                    initial={{ opacity: 1, x: 200 }}
                                    animate={hasAnimated ? { opacity: 1, x: 0 } : { opacity: 0, x: 200 }}
                                    transition={{ duration: 1.5 }}
                                    onViewportEnter={handleAnimation}
                                >
                                    <div className="feature-card">
                                        <div className="card-icon">
                                            <FontAwesomeIcon icon={faBolt} aria-hidden="true" style={{ color: "#56289f" }} />
                                        </div>
                                        <span className="span">
                                            <strong>{text}</strong>
                                        </span>
                                    </div>
                                </motion.li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Info;
