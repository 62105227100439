// Alert.jsx
import React, { useState } from 'react';
import './Alert.css'; 

const Alert = ({ type, message }) => {
  const [isVisible, setIsVisible] = useState(true);

  const handleClose = () => {
    setIsVisible(false);
  };

  if (!isVisible) return null;

  let alertClass = '';
  switch(type) {
   
    case 'info':
      alertClass = 'alert alert-info';
      break;
    case 'error':
      alertClass = 'alert alert-error';
      break;
    case 'success':
      alertClass = 'alert alert-success';
      break;
    default:
      alertClass = 'alert';
  }

  return (
    
    <div className={alertClass}>
      {message}
      
    </div>
  );
};

export default Alert;
