import React from 'react';
import './Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebook, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import Glitch from '../Glitch-logo/Glitch';

function Footer({ onButtonClick }) {
  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleButtonClick = () => {
    scrollToSection('home');
  };


  const currentYear = new Date().getFullYear();

  return (
    <div className="footer-f">
      <div className="view grid-list">
        <div className="footer-brand">
          <div className="company-name">
            <Glitch size="20px" />
          </div>
          <div className="company-content">
            <p className="company">
              &copy; {currentYear} AISlideStudio <br /> All rights reserved.
            </p>
            <ul className="social-list">
              <li>
                <a href="#" className="social-link">
                  <FontAwesomeIcon icon={faFacebook} />
                </a>
              </li>
              <li>
                <a href="#" className="social-link">
                  <FontAwesomeIcon icon={faInstagram} />
                </a>
              </li>
              <li>
                <a href="https://x.com/aislidestudio_" className="social-link">
                  <FontAwesomeIcon icon={faXTwitter} />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <ul className="footer-list">
          <li>
            <p className="h4 footer-list-title">Get in Touch</p>
          </li>
          <li>
            <address className="footer-text">
              Istanbul, Turkey
            </address>
          </li>
          <li>
            <a href="mailto:faseehbilal55@gmail.com" className="footer-link">faseehbilal55@gmail.com</a>
          </li>
        </ul>
        <ul className="footer-list">
          <li>
            <p className="h4 footer-list-title">Learn More</p>
          </li>
          <li className='footer-link' onClick={() => scrollToSection('home')}>Home</li>
          <li className='footer-link' onClick={() => scrollToSection('about')}>About</li>
          <li className='footer-link' onClick={() => scrollToSection('pricing')}>Pricing</li>
          <li className='footer-link' onClick={() => scrollToSection('faqs')}>FAQs</li>
          <li className='footer-link' onClick={() => scrollToSection('contact')}>Contact</li>
        </ul>
        <div className="butn">
          <button onClick={handleButtonClick} className="button">Try For Free</button>
        </div>
      </div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 24 150 28"
        preserveAspectRatio="none"
        className="footer-wave"
      >
        <defs>
          <path
            id="gentle-wave"
            d="M-160 44c30 0 58-18 88-18s58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
          />
        </defs>
        <g className="waves">
          <use xlinkHref="#gentle-wave" x="50" y="0" fill="#56289f" fillOpacity=".2" />
          <use xlinkHref="#gentle-wave" x="50" y="3" fill="#56289f" fillOpacity=".5" />
          <use xlinkHref="#gentle-wave" x="50" y="6" fill="#56289f" fillOpacity=".9" />
        </g>
      </svg>
    </div>
  );
}

export default Footer;
