import React, { useState, useEffect } from 'react';
import './Header.css';
import Glitch from '../Glitch-logo/Glitch';

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    if (window.innerWidth < 768) {
      setIsMenuOpen(false);
    }
  };

  const handleScroll = () => {
    setIsScrolled(window.scrollY > 50);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768) {
        setIsMenuOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className={`header-wrapper ${isScrolled ? 'scrolled' : ''}`}>
      <nav className="navigation">
        <div className="logo">
          <Glitch size="30px" />
        </div>

        <ul className={`menu-list ${isMenuOpen ? 'open' : ''}`}>
          <li>
            <a href="#" onClick={(e) => { e.preventDefault(); scrollToSection('home'); closeMenu(); }}>Home</a>
          </li>
          <li>
            <a href="#" className='overlapping-issue' onClick={(e) => { e.preventDefault(); scrollToSection('about'); closeMenu(); }}>About</a>
          </li>
          <li className='pricing'>
            <a href="#" onClick={(e) => { e.preventDefault(); scrollToSection('pricing'); closeMenu(); }} style={{ textDecoration: 'line-through' }}>Pricing</a>
            <span className="free-label">Free</span>
          </li>
          <li>
            <a href="#" onClick={(e) => { e.preventDefault(); scrollToSection('faqs'); closeMenu(); }}>FAQs</a>
          </li>
          <li>
            <a href="#" onClick={(e) => { e.preventDefault(); scrollToSection('contact'); closeMenu(); }}>Contact</a>
          </li>
          <div className="button-container-h">
            <button className='button' onClick={() => scrollToSection('home')}>Try For Free</button>
          </div>
        </ul>

        <div className="hamburger" onClick={toggleMenu}>
          <div className="bar" />
          <div className="bar2 bar" />
          <div className="bar" />
        </div>
      </nav>
    </div>
  );
}

export default Header;
