import React, { useState } from 'react';
import './FontPicker.css'
const FontPicker = ({ selectedFont, setSelectedFont, labelText, labelFontSize}) => {
 


  const fonts = [
    'Arial',           
    'Arial Black',      
    'Calibri',          
    'Cambria',          
    'Times New Roman', 
    'Georgia',          
    'Tahoma',           
    'Verdana',         
    'Garamond',         
    'Trebuchet MS',    
    'Courier New',      
    'Comic Sans MS', 
    'Impact',           
    'Lucida Console',   
    'Palatino Linotype',
    'Book Antiqua',    
    'Century Gothic',   
    'Frank Ruhl',       
    'Baskerville',     
];


  const handleChange = (event) => {
    const font = event.target.value;
    setSelectedFont(font);
    
  };

  return (
    <div className="main-f">
        <div className="container-f">
    <div style={{ fontFamily: selectedFont, marginBottom: '20px' }}>
      <label className='text-f' htmlFor="font-picker" style={{ marginRight: '10px', fontSize: labelFontSize }} >
      {labelText}
      </label>
      <select
      className='font-picker'
        id="font-picker"
        value={selectedFont}
        onChange={handleChange}
        style={{
          fontFamily: selectedFont,
          padding: '10px',
          fontSize: '16px',
         
         
        }}
      >
        {fonts.map((font) => (
          <option key={font} value={font} style={{ fontFamily: font }}>
            {font}
          </option>
        ))}
      </select>
    </div>
    </div>
    </div>
  );
};

export default FontPicker;
