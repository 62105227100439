import React, { useState } from 'react';
import './Faq.css'; 
import { motion } from 'framer-motion';

const Faq = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [hasAnimated, setHasAnimated] = useState(false);

  const handleQuestionClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleLinkClick = (event, id) => {
    event.preventDefault();
    scrollToSection(id);
  };

  const handleAnimation = () => {
    if (!hasAnimated) {
      setHasAnimated(true);
    }
  };

  return (
    <div className="faq-main" id="faqs">
      <div className="text-faq">
        <span>Questions? We got answers!</span>
        <motion.h1
          className="heading-faq"
          initial={{ opacity: 0, y: 50 }}
          animate={hasAnimated ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
          transition={{ duration: 1.5 }}
          onViewportEnter={handleAnimation}
        >
          FREQUENTLY ASKED QUESTIONS
        </motion.h1>
      </div>

      <div className="wrapper-faq">
        {faqData.map((item, index) => (
          <motion.div
            className="container-faq"
            key={index}
            initial={{ opacity: 0, y: 200 }}
            animate={hasAnimated ? { opacity: 1, y: 0 } : { opacity: 0, y: 200 }}
            transition={{ duration: 1.5 }}
            onViewportEnter={handleAnimation}
          >
            <div
              className={`question ${activeIndex === index ? "active" : ""}`}
              onClick={() => handleQuestionClick(index)}
            >
              {item.question}
            </div>
            <div
              className="answercont"
              style={{
                maxHeight: activeIndex === index ? "500px" : "0",
                overflow: "hidden",
                transition: "max-height 0.3s ease",
              }}
            >
              <div className="answer">
                {item.answer}
                <br />
                <br />
                <a href={item.link} onClick={(e) => handleLinkClick(e, item.link)}>Learn More</a>
              </div>
            </div>
          </motion.div>
        ))}
      </div>

      <script src="https://storage.ko-fi.com/cdn/scripts/overlay-widget.js"></script>
      <script
        dangerouslySetInnerHTML={{
          __html: `
            kofiWidgetOverlay.draw('mohamedghulam', {
              'type': 'floating-chat',
              'floating-chat.donateButton.text': 'Support me',
              'floating-chat.donateButton.background-color': '#323842',
              'floating-chat.donateButton.text-color': '#fff'
            });
          `,
        }}
      />
    </div>
  );
};

const faqData = [
  {
    question: "What is AI Slide Studio?",
    answer: `Creating a presentation with AI Slide Studio is simple and fast. Just enter your topic into the platform, and within seconds, the AI will generate a set of 10 slides. You can then fully edit and customize these slides to suit your needs. Once you’re satisfied with your presentation, click the download button to get your file.`,
    link: "home",
  },
  {
    question: "How do I create a presentation with AI Slide Studio?",
    answer: `To create a presentation, input your topic into AI Slide Studio. The AI will then generate a set of 10 slides in few seconds. You can fully edit and customize these slides to fit your needs. After that, just click on the download button, and it will be downloaded.`,
    link: "home",
  },
  {
    question: "Can I edit presentations after they are downloaded?",
    answer: `Yes, presentations are generated in PowerPoint pptx format, allowing you to edit and customize them as needed. You can modify text, images, and slide layouts to ensure your presentation meets your specific requirements.`,
    link: "home",
  },
  {
    question: "Can I edit presentations directly on AI Slide Studio after they are generated?",
    answer: `Yes, AI Slide Studio lets you make several edits directly on the platform. You can change font colors, modify text, choose from a variety of stylish plain slide background colors, and apply eye-catching themes to make your presentations stand out. For further customization, you can download the presentations in PowerPoint format and make additional edits using any pptx editor.`,
    link: "home",
  },
  {
    question: "Is AI Slide Studio free to use?",
    answer: `AI Slide Studio offers a free plan that allows you to generate unlimited presentations with access to our editor on the platform. For downloading presentations, we offer various subscription plans. Check our pricing page for more details.`,
    link: "pricing",
  },
  {
    question: "Which formats does AI Slide Studio support?",
    answer: `AI Slide Studio generates presentations in PowerPoint (PPTX) format. You can download and integrate your slides with Google Slides or Microsoft PowerPoint.`,
    link: "home",
  },
];

export default Faq;
