import React from 'react'
import Main from './components/Main/Main'
import Footer from './components/Footer/Footer'
import Info from './components/Info/Info'
import Pricing from './components/Pricing/Pricing'
import Faq from './components/Faq/Faq'
import Header from './components/Header/Header'
import { Analytics } from "@vercel/analytics/react"
import './App.css'

function App() {
  return (
    <div className='app'>
    <Header/>
      <Main/>
     <Info/>
     <Pricing/>
     <Faq/>
   <Footer/>
   <Analytics />
    </div>
  )
}

export default App
